body {
  background-color: rgb(34, 34, 34);
}

* {
  font-family: Roboto, sans-serif;
}

#container,
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  font-size: 1.8em;
  font-weight: bold;
  color: white;
  margin:15px 0;
}

input,
button {
  background-color: rgb(34, 34, 34);
  border: 2px solid white;
  border-radius: 10px;
  font-size: 1.3em;
  color: white;
  padding: 5px 10px;
}

button {
  font-weight: bold;
  margin: 15px 0;
  padding: 7px 40px;
  transition: 0.1s;
}

button:hover {
  background-color: white;
  border: 2px solid rgb(34, 34, 34);
  color: rgb(34, 34, 34);
}

ul {
  list-style: none;
  padding: 0;
}

h1 {
  font-size: 1.7em;
  color: white;
  margin: 0px;
}

p {
  font-size: 1.2em;
  color: white;
  margin: 0px 25px;
  overflow-wrap: break-word;
  max-width: 75vw;
}

.buttons h2 {
  font-size: 1.4em;
  color: white;
  margin: 0;
}

.buttons {
  display: flex;
  margin-left: auto;
}

#deleteBtn {
  margin-right: 10px;
}

#closeEditTaskBtn {
  margin-left: 10px;
}

.task {
  display: flex;
  align-items: center;
  width: 100%;
}

.taskDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(51, 51, 51);
  border-radius: 5px;
  margin-top: 25px;
  padding: 15px 25px;
}

.taskEdit {
  margin-top: 15px;
  margin-bottom: 10px;
  width: 98%;
}

@media (max-width: 925px) {
  p {
    margin: 15px 0;
    text-align: center;
  }

  .buttons {
    margin: 0;
  }

  .task {
    flex-direction: column;
  }

  .taskDiv {
    padding: 15px;
  }

  .taskEdit {
    width: 65vw;
  }
}